:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

.card-member {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  width: 100%;
  color: #005666;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.card-member[href] {
  text-decoration: none;
}
.card-member[href]:hover .card-body {
  color: #fff;
  background-color: #005666;
}
.card-member[href]:hover .card-body .company-name {
  color: inherit;
}
.card-member .card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 182px;
  padding: 1rem;
}
.card-member .card-img-wrapper svg,
.card-member .card-img-wrapper img {
  width: 100%;
}
.card-member .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  padding: 0.5rem 1rem;
  min-height: 52px;
  transition: background-color 0.3s ease-in-out;
}
.card-member .company-name {
  font-size: 14px;
  color: #005666;
  transition: color 0.3s ease-in-out;
}
@supports (-webkit-appearance:none){

}
