:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

.carbon-calculator-section {
  background-color: #fff;
  padding: 40px 0;
}
.carbon-calculator-section .col-img .img-holder img {
  width: auto;
}
.carbon-calculator-section .section-title span {
  font-weight: 400;
}
.carbon-calculator-section .section-text {
  margin-bottom: 1.5rem;
}
.carbon-calculator-section .section-text h3 strong {
  position: relative;
  display: inline-block;
  font-size: 28px;
  letter-spacing: -0.4px;
}
.carbon-calculator-section .section-text h3 strong::before {
  position: absolute;
  left: -1.5rem;
  right: -1.5rem;
  top: -1rem;
  bottom: -1rem;
  content: "";
  background-color: rgba(0, 204, 204, 0.1);
}
@media (max-width: 767.98px) {
  .carbon-calculator-section .col-img {
    margin-top: 1.5rem;
  }
  .carbon-calculator-section .col-img .img-holder {
    text-align: center;
  }
  .carbon-calculator-section .btn-holder {
    margin-bottom: 1rem;
  }
  .carbon-calculator-section .btn-holder .btn {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .carbon-calculator-section {
    padding: 64px 0;
  }
  .carbon-calculator-section .section-inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
    align-items: center;
  }
  .carbon-calculator-section .col-text,
  .carbon-calculator-section .col-img {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    flex: 1 1 0;
  }
  .carbon-calculator-section .col-img .img-holder {
    padding-left: 1.5rem;
  }
  .carbon-calculator-section .section-title {
    margin-bottom: 3rem;
  }
  .carbon-calculator-section .section-text {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 3rem;
  }
}
@media (min-width: 992px) {
  .carbon-calculator-section .col-img {
    max-width: 33.33334%;
    flex: 0 0 33.33334%;
  }
  .carbon-calculator-section .col-text {
    margin-left: 16.66667%;
    max-width: 50%;
    flex: 0 0 50%;
  }
}
@supports (-webkit-appearance:none){

}

 @media (max-width: 767.98px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 768px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 992px){

@supports (-webkit-appearance:none){

}

}
