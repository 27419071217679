@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/maps';

@import '../../src/scss/components/vars';
@import '../../src/scss/components/mixins';

@import '../../src/scss/components/swiper-styles';

.testimonials-section {
  padding: 40px 0;
  background-color: $gray-lightest2;
  .section-title {
    margin-bottom: 24px;
    //margin-bottom: 48px;
  }
  .section-text {
    margin-bottom: 24px;
    //margin-bottom: 48px;
  }
  @include media-breakpoint-down(md) {
    .btn-holder {
      .btn {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding: 64px 0;
    .home & {
      //padding: 80px 0;
    }
    .section-title {
      margin-bottom: 64px;
    }
    .section-text {
      font-size: 20px;
      margin-bottom: 64px;
    }
  }
}

.js-swiper-testimonials {
  padding: 20px;
  margin: -20px -20px 16px;
  .swiper-slide {
    height: auto;
    .card-testimonial {
      height: 100%;
      margin-bottom: 0;
    }
  }
  .swiper-pagination-fraction {
    font-size: 22px;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 48px;
    margin-left: -50vw;
    margin-right: -50vw;
    padding-left: 50vw;
    padding-right: 50vw;
    .swiper-slide {
      //width: 83.33334%;
    }
  }
}
.card-testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: $white;
  border-radius: $radius-sm;
  box-shadow: $box-shadow-alt2;
  .card {
    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 258px;
      padding: 32px;
      img,svg {
        width: 100%;
      }
    }
    &-body {
      position: relative;
      padding: 32px;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 1px;
        background-color: $gray-lightest;
      }
    }
    &-text {
      color: $primary-dark;
      margin-bottom: 1rem;
    }
    &-footer {
      display: flex;
      align-items: center;
    }
  }
  .author-img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 24px;
    overflow: hidden;
  }
  @include media-breakpoint-down(lg) {
    .img-wrapper {
      text-align: center;
      img {
        max-width: 70%;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    //box-shadow: $box-shadow-cards-desk;
    .name,
    .company-name {
      font-size: 18px;
    }
    .company-name {
      font-weight: 500;
    }
    .card {
      &-img {
        flex: 1 1 0;
      }
      &-body {
        flex: 1 1 0;
        &::before {
          top: 40px;
          left: 0;
          bottom: 40px;
          width: 1px;
          height: auto;
          background-color: $gray-lightest;
        }
      }
      &-text {
        font-size: 22px;
        font-weight: 700;
        line-height: 1.6;
        max-width: 500px;
        margin-bottom: 1.5rem;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    min-height: 436px;
  }
}