.js-swiper-members,
.js-swiper-testimonials {
  display: flex;
  flex-wrap: wrap;
  .swiper-pagination-fraction {
    position: static;
    display: inline-flex;
    align-items: center;
    height: 64px;
    width: auto;
    transform: none;
    margin-top: 44px;
    &.swiper-pagination-lock {
      display: none;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: static;
    display: inline-flex;
    width: 64px;
    height: 64px;
    color: $gray-darkest2;
    margin-top: 44px;
    margin-right: 1rem;
    svg {
      width: 70%;
      height: 70%;
    }
    &:after {
      display: none;
    }
    &:hover {
      color: $primary-dark;
    }
    &:focus-visible {
      box-shadow: $focus-visible;
    }
    &.swiper-button-disabled {
      opacity: 1;
      color: $gray-light2;
    }
    &.swiper-button-lock {
      display: none;
    }
  }
  @include media-breakpoint-up(md) {
    .swiper-pagination-fraction,
    .swiper-button-prev,
    .swiper-button-next {
      margin-top: 32px;
    }
  }
}