@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/maps';

@import '../../src/scss/components/vars';
@import '../../src/scss/components/mixins';

$hero-padding-desktop: 64px;

.hero-section {
  background-color: $white;
  padding: 32px 0;
  .hero-title {
    letter-spacing: -.5px;
    line-height: 1.2;
    span {
      font-weight: 400;
    }
  }
  .hero-text {
    margin-bottom: 1.5rem;
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .col-img {
    .img-holder {
      text-align: center;
      img {
        width: auto;
        max-height: 60vh;
      }
    }
    &.col-full-width {
      .img-holder {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .col-img {
      display: none;
    }
    .col-text {
      &:only-child {
        margin-top: -8px;
        margin-bottom: -8px;
      }
    }
    .btn-holder {
      margin-bottom: 1rem;
      .btn {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding: $hero-padding-desktop 0;
    .section-inner {
      @include row;
      align-items: center;
    }
    .col-text,
    .col-img {
      @include col;
    }
    .col-img {
      &.col-full-width {
        position: relative;
        align-self: stretch;
        margin-top: -$hero-padding-desktop;
        margin-bottom: -$hero-padding-desktop;
        .img-holder {
          position: absolute;
          left: calc($grid-gutter-width/2);
          top: 0;
          bottom: 0;
          width: calc(50vw - $grid-gutter-width/2);
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .col-text {
      &:only-child {
        .hero-title {
          margin-bottom: 1rem;
        }
      }
    }
    .hero-title {
      margin-bottom: 40px;
    }
    .home & {
      .hero-title {
        font-size: 40px;
        //margin-bottom: 154px;
        margin-bottom: 8.6vw;
      }
    }
    .hero-text {
      font-size: 20px;
      line-height: 1.6;
      margin-bottom: 3rem;
    }
  }
}


.anchor-nav {
  .anchor-nav-title {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 700;
    margin-right: 4px;
  }
  .anchor-link {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    padding: 9px 12px;
    color: $body-color;
    background-color: $white;
    border: 1px solid $primary-dark;
    border-radius: 3rem;
    margin: 4px 2px;
    text-decoration: none;
    transition: color $tr-anim, background-color $tr-anim;
    &:hover {
      color: $white;
      background-color: $primary-dark;
    }
  }
}