@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/maps';

@import '../../src/scss/components/vars';
@import '../../src/scss/components/mixins';

.statistics-section {
  padding: 40px 0;
  .section-title {
    margin-bottom: 48px;
  }
  .card-simple-group {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-down(md) {
    .btn-holder {
      .btn {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding: $margin-lg 0;
    .home & {
      //padding: 80px 0;
    }
    .section-title {
      margin-bottom: 64px;
    }
  }
}


.card-simple-group {
  display: flex;
  flex-wrap: wrap;
  gap: 40px $grid-gutter-width;
  margin: 40px 0;
  @include media-breakpoint-up(sm) {
    .card-simple {
      width: calc(50% - 16px);
    }
  }
  @include media-breakpoint-up(md) {
    margin: 64px 0;
  }
  @include media-breakpoint-up(xl) {
    .card-simple {
      width: calc(25% - 24px);
    }
  }
}
.card-simple {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 28px 32px;
  color: $white;
  background-color: $primary-dark;
  border-radius: $radius;
  box-shadow: $box-shadow-alt;
  overflow: hidden;
  .text-dark & {
    color: $gray-darker;
  }
  .card {
    &-title {
      font-family: $ff;
      font-size: 72px;
      font-weight: 700;
      color: $white;
      margin-bottom: 0;
    }
    &-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.6;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 10px;
      }
    }
  }
}