:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

.latest-news-section {
  padding: 40px 0;
}
.latest-news-section.section-bg {
  background-color: #fff;
}
.latest-news-section .section-title {
  margin-bottom: 40px;
}
@media (max-width: 767.98px) {
  .latest-news-section .btn-holder .btn {
    width: 100%;
  }
  .latest-news-section .btn-holder.desktop-hidden {
    text-align: center;
  }
  .latest-news-section .btn-holder.desktop-hidden .btn {
    width: auto;
  }
  .latest-news-section .heading .btn-holder {
    display: none;
  }
  .latest-news-section .card-news-group + .btn-holder.desktop-hidden {
    margin-top: -1rem;
  }
}
@media (min-width: 768px) {
  .latest-news-section {
    padding: 64px 0;
  }
  .latest-news-section .heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
  }
  .latest-news-section .heading .section-title {
    margin-bottom: 0;
  }
  .latest-news-section .section-title {
    margin-bottom: 64px;
  }
}
@supports (-webkit-appearance:none){

}

 @media (max-width: 767.98px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 768px){

@supports (-webkit-appearance:none){

}

}
