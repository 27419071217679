:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

.hero-section {
  background-color: #fff;
  padding: 32px 0;
}
.hero-section .hero-title {
  letter-spacing: -0.5px;
  line-height: 1.2;
}
.hero-section .hero-title span {
  font-weight: 400;
}
.hero-section .hero-text {
  margin-bottom: 1.5rem;
}
.hero-section .hero-text p:last-child {
  margin-bottom: 0;
}
.hero-section .hero-text:last-child {
  margin-bottom: 0;
}
.hero-section .col-img .img-holder {
  text-align: center;
}
.hero-section .col-img .img-holder img {
  width: auto;
  max-height: 60vh;
}
.hero-section .col-img.col-full-width .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767.98px) {
  .hero-section .col-img {
    display: none;
  }
  .hero-section .col-text:only-child {
    margin-top: -8px;
    margin-bottom: -8px;
  }
  .hero-section .btn-holder {
    margin-bottom: 1rem;
  }
  .hero-section .btn-holder .btn {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .hero-section {
    padding: 64px 0;
  }
  .hero-section .section-inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
    align-items: center;
  }
  .hero-section .col-text,
  .hero-section .col-img {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    flex: 1 1 0;
  }
  .hero-section .col-img.col-full-width {
    position: relative;
    align-self: stretch;
    margin-top: -64px;
    margin-bottom: -64px;
  }
  .hero-section .col-img.col-full-width .img-holder {
    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    width: calc(50vw - 16px);
  }
  .hero-section .col-img.col-full-width .img-holder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .hero-section .col-text:only-child .hero-title {
    margin-bottom: 1rem;
  }
  .hero-section .hero-title {
    margin-bottom: 40px;
  }
  .home .hero-section .hero-title {
    font-size: 40px;
    margin-bottom: 8.6vw;
  }
  .hero-section .hero-text {
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 3rem;
  }
}

.anchor-nav .anchor-nav-title {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 700;
  margin-right: 4px;
}
.anchor-nav .anchor-link {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  padding: 9px 12px;
  color: #1D1D1D;
  background-color: #fff;
  border: 1px solid #005666;
  border-radius: 3rem;
  margin: 4px 2px;
  text-decoration: none;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.anchor-nav .anchor-link:hover {
  color: #fff;
  background-color: #005666;
}
@supports (-webkit-appearance:none){

}

 @media (max-width: 767.98px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 768px){

@supports (-webkit-appearance:none){

}

}
