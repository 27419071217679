:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

.cards-section {
  padding: 40px 0;
}
.cards-section.section-bg {
  background-color: #fff;
}
.cards-section .section-title,
.cards-section .section-text {
  margin-bottom: 24px;
}
.home .cards-section .section-title,
.home .cards-section .section-text {
  margin-bottom: 48px;
}
@media (max-width: 767.98px) {
  .cards-section .btn-holder .btn {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .cards-section {
    padding: 64px 0;
  }
  .cards-section.section-bg + .cards-section.section-bg {
    margin-top: -64px;
  }
  .cards-section:not(.section-bg) + .cards-section:not(.section-bg) {
    margin-top: -64px;
  }
  .cards-section:not(.section-bg) + .join-section {
    margin-top: -64px;
  }
  .cards-section .section-title {
    margin-bottom: 48px;
  }
  .cards-section .section-text {
    font-size: 22px;
    line-height: 1.6;
    margin-bottom: 48px;
  }
  .cards-section.two_col_desktop .section-text {
    max-width: 877px;
  }
  .cards-section.two_col_desktop .card-default .card-img {
    height: 342px;
  }
  .home .cards-section .section-title,
  .home .cards-section .section-text {
    margin-bottom: 64px;
  }
}

.card-default-group {
  display: flex;
  flex-wrap: wrap;
  gap: 48px 32px;
  margin: 24px 0;
}
.home .card-default-group {
  margin: 48px 0;
}
.card-default-group:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .card-default-group {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .home .card-default-group {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .card-default-group .card-default {
    width: calc(50% - 16px);
  }
}
@media (min-width: 1200px) {
  .card-default-group .card-default {
    width: calc(25% - 24px);
  }
  .card-default-group.two_col_desktop .card-default {
    width: calc(50% - 16px);
  }
  .card-default-group.three_col_desktop .card-default {
    width: calc(33.33334% - 22px);
  }
}

.card-default {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px 8px 12px 12px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 90, 0.1);
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
}
.card-default[href] {
  text-decoration: none;
}
.card-default[href]:hover {
  background-color: #F2F2F2;
}
.card-default .card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  height: 258px;
}
.card-default .card-body {
  flex-grow: 1;
  padding: 32px;
  border-radius: 0 0 12px 12px;
}
.card-default .card-title {
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-weight: 700;
  color: #005666;
  margin-bottom: 1rem;
}
.card-default .card-text {
  font-size: 16px;
  margin-bottom: 1rem;
}
.card-default .card-text:last-child {
  margin-bottom: 0;
}
.card-default.photo .card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-default.vector .card-img img {
  width: 80%;
  height: 80%;
}
@media (max-width: 767.98px) {
  .card-default .card-text {
    font-size: 1rem;
  }
  .home .card-default .card-text {
    font-size: 20px;
  }
}
@supports (-webkit-appearance:none){

}

 @media (max-width: 767.98px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 768px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 1200px){

@supports (-webkit-appearance:none){

}

}
