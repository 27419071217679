@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/variables-dark';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/maps';

@import '../../../src/scss/components/vars';
@import '../../../src/scss/components/mixins';

.card-news-group {
  display: flex;
  flex-wrap: wrap;
  gap: 40px $grid-gutter-width;
  margin: 40px 0;
  @include media-breakpoint-up(md) {
    .card-news {
      width: calc(50% - 16px);
    }
  }
  @include media-breakpoint-up(xl) {
    .card-news {
      width: calc(33.33334% - 22px);
    }
  }
}
.card-news {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $white;
  border-radius: $radius-sm;
  //border: 1px solid $gray-lightest;
  //box-shadow: $box-shadow-cards;
  box-shadow: $box-shadow-alt;
  overflow: hidden;
  &[href] {
    color: $body-color;
    text-decoration: none;
    &:hover {
      color: $white;
      .card-body {
        background-color: $primary-dark;
      }
      .card-title {
        text-decoration: underline;
      }
    }
  }
  .card {
    &-img {
      position: relative;
      width: 100%;
      height: 275px;
      background-color: $gray-lightest;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .cat-holder {
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
    &-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 32px;
      transition: background-color $tr-anim;
    }
    &-inner {
      flex-grow: 1;
    }
    &-title {
      font-family: $ff;
      font-size: 20px;
      line-height: 1.4;
      font-weight: 600;
      color: inherit;
      margin-bottom: 1rem;
    }
    &-text {
      font-size: 1rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      margin-bottom: 1rem;
    }
  }
  .date {
    font-size: 14px;
    font-weight: 700;
  }
}
.cat {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 1rem 2rem;
  color: $body-color;
  background-color: $secondary;
}
