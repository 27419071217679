:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

.js-swiper-members,
.js-swiper-testimonials {
  display: flex;
  flex-wrap: wrap;
}
.js-swiper-members .swiper-pagination-fraction,
.js-swiper-testimonials .swiper-pagination-fraction {
  position: static;
  display: inline-flex;
  align-items: center;
  height: 64px;
  width: auto;
  transform: none;
  margin-top: 44px;
}
.js-swiper-members .swiper-pagination-fraction.swiper-pagination-lock,
.js-swiper-testimonials .swiper-pagination-fraction.swiper-pagination-lock {
  display: none;
}
.js-swiper-members .swiper-button-prev,
.js-swiper-members .swiper-button-next,
.js-swiper-testimonials .swiper-button-prev,
.js-swiper-testimonials .swiper-button-next {
  position: static;
  display: inline-flex;
  width: 64px;
  height: 64px;
  color: #1E2626;
  margin-top: 44px;
  margin-right: 1rem;
}
.js-swiper-members .swiper-button-prev svg,
.js-swiper-members .swiper-button-next svg,
.js-swiper-testimonials .swiper-button-prev svg,
.js-swiper-testimonials .swiper-button-next svg {
  width: 70%;
  height: 70%;
}
.js-swiper-members .swiper-button-prev:after,
.js-swiper-members .swiper-button-next:after,
.js-swiper-testimonials .swiper-button-prev:after,
.js-swiper-testimonials .swiper-button-next:after {
  display: none;
}
.js-swiper-members .swiper-button-prev:hover,
.js-swiper-members .swiper-button-next:hover,
.js-swiper-testimonials .swiper-button-prev:hover,
.js-swiper-testimonials .swiper-button-next:hover {
  color: #005666;
}
.js-swiper-members .swiper-button-prev:focus-visible,
.js-swiper-members .swiper-button-next:focus-visible,
.js-swiper-testimonials .swiper-button-prev:focus-visible,
.js-swiper-testimonials .swiper-button-next:focus-visible {
  box-shadow: 0 0 0 2px #F2F2F2, 0 0 0 4px #005666;
}
.js-swiper-members .swiper-button-prev.swiper-button-disabled,
.js-swiper-members .swiper-button-next.swiper-button-disabled,
.js-swiper-testimonials .swiper-button-prev.swiper-button-disabled,
.js-swiper-testimonials .swiper-button-next.swiper-button-disabled {
  opacity: 1;
  color: #D9D9D9;
}
.js-swiper-members .swiper-button-prev.swiper-button-lock,
.js-swiper-members .swiper-button-next.swiper-button-lock,
.js-swiper-testimonials .swiper-button-prev.swiper-button-lock,
.js-swiper-testimonials .swiper-button-next.swiper-button-lock {
  display: none;
}
@media (min-width: 768px) {
  .js-swiper-members .swiper-pagination-fraction,
  .js-swiper-members .swiper-button-prev,
  .js-swiper-members .swiper-button-next,
  .js-swiper-testimonials .swiper-pagination-fraction,
  .js-swiper-testimonials .swiper-button-prev,
  .js-swiper-testimonials .swiper-button-next {
    margin-top: 32px;
  }
}

.members-section {
  padding: 40px 0;
}
.members-section .section-title {
  margin-bottom: 24px;
}
.members-section .section-title span {
  font-weight: 400;
}
.members-section .section-text {
  margin-bottom: 24px;
}
@media (max-width: 767.98px) {
  .members-section .btn-holder .btn {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .members-section {
    padding: 64px 0;
  }
  .members-section .section-title {
    font-size: 40px;
    margin-bottom: 64px;
  }
  .members-section .section-text {
    font-size: 20px;
    margin-bottom: 64px;
  }
}

.js-swiper-members {
  padding: 20px;
  margin: -20px -20px 16px;
}
.js-swiper-members .swiper-wrapper {
  height: 488px;
  justify-content: space-between;
  align-content: flex-start;
}
.js-swiper-members .swiper-slide {
  height: calc((100% - 20px) / 2);
  box-sizing: border-box;
}
.js-swiper-members .swiper-slide .card-member {
  height: 100%;
}
.js-swiper-members .swiper-pagination-fraction {
  font-size: 1rem;
  color: #005666;
}
@media (min-width: 768px) {
  .js-swiper-members {
    margin-bottom: 48px;
  }
  .js-swiper-members .swiper-wrapper {
    height: 516px;
  }
  .js-swiper-members .swiper-slide {
    height: calc((100% - 32px) / 2);
  }
  .js-swiper-members .swiper-pagination-fraction {
    font-size: 22px;
  }
}
@supports (-webkit-appearance:none){

}

 @media (min-width: 768px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 767.98px){

@supports (-webkit-appearance:none){

}

}
