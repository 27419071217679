:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

.statistics-section {
  padding: 40px 0;
}
.statistics-section .section-title {
  margin-bottom: 48px;
}
.statistics-section .card-simple-group:first-child {
  margin-top: 0;
}
.statistics-section .card-simple-group:last-child {
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .statistics-section .btn-holder .btn {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .statistics-section {
    padding: 64px 0;
  }
  .statistics-section .section-title {
    margin-bottom: 64px;
  }
}

.card-simple-group {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 32px;
  margin: 40px 0;
}
@media (min-width: 576px) {
  .card-simple-group .card-simple {
    width: calc(50% - 16px);
  }
}
@media (min-width: 768px) {
  .card-simple-group {
    margin: 64px 0;
  }
}
@media (min-width: 1200px) {
  .card-simple-group .card-simple {
    width: calc(25% - 24px);
  }
}

.card-simple {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 28px 32px;
  color: #fff;
  background-color: #005666;
  border-radius: 12px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 90, 0.1);
  overflow: hidden;
}
.text-dark .card-simple {
  color: #1D1D1D;
}
.card-simple .card-title {
  font-family: "Roboto", sans-serif;
  font-size: 72px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
}
.card-simple .card-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 1rem;
}
.card-simple .card-text:last-child {
  margin-bottom: 10px;
}
@supports (-webkit-appearance:none){

}

 @media (max-width: 767.98px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 768px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 576px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 1200px){

@supports (-webkit-appearance:none){

}

}
