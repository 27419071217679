@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/variables-dark';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/maps';

@import '../../../src/scss/components/vars';
@import '../../../src/scss/components/mixins';

.card-member {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  width: 100%;
  color: $primary-dark;
  background-color: $white;
  border-radius: $radius-sm;
  box-shadow: $box-shadow-cards;
  overflow: hidden;

  &[href] {
    text-decoration: none;
    &:hover {
      .card-body {
        color: $white;
        background-color: $primary-dark;
        .company-name {
          color: inherit;
        }
      }
    }
  }
  .card {
    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 182px;
      padding: 1rem;
      &-wrapper {
        svg,
        img {
          width: 100%;
        }
      }
    }
    &-body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      //position: relative;
      text-align: center;
      padding: .5rem 1rem;
      min-height: 52px;
      transition: background-color $tr-anim;
    }
  }
  .company-name {
    font-size: 14px;
    color: $primary-dark;
    transition: color $tr-anim;
    //margin-bottom: 1rem;
  }
  @include media-breakpoint-up(md) {
  }
}
