@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/maps';

@import '../../src/scss/components/vars';
@import '../../src/scss/components/mixins';

@import '../../src/scss/components/swiper-styles';

.members-section {
  padding: 40px 0;
  .section-title {
    margin-bottom: 24px;
    //margin-bottom: 48px;
    span {
      font-weight: 400;
    }
  }
  .section-text {
    margin-bottom: 24px;
    //margin-bottom: 48px;
  }
  @include media-breakpoint-down(md) {
    .btn-holder {
      .btn {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding: $margin-lg 0;
    .home & {
      //padding: 80px 0;
    }
    .section-title {
      font-size: 40px;
      margin-bottom: 64px;
    }
    .section-text {
      font-size: 20px;
      margin-bottom: 64px;
    }
  }
}

.js-swiper-members {
  padding: 20px;
  margin: -20px -20px 16px;
  .swiper-wrapper {
    height: 488px; // card height 234 + vertical space 20
    justify-content: space-between;
    align-content: flex-start;
  }
  .swiper-slide {
    height: calc((100% - 20px) / 2); // 20 - vertical space on mobile between cards
    box-sizing: border-box;
    .card-member {
      height: 100%;
    }
  }
  .swiper-pagination-fraction {
    font-size: 1rem;
    color: $primary-dark;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 48px;
    .swiper-wrapper {
      height: 516px; // card height 242 + vertical space 32
    }
    .swiper-slide {
      height: calc((100% - 32px) / 2); // 32 - vertical space on mobile between cards
    }

    .swiper-pagination-fraction {
      font-size: 22px;
    }
  }
}
