:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

.js-swiper-members,
.js-swiper-testimonials {
  display: flex;
  flex-wrap: wrap;
}
.js-swiper-members .swiper-pagination-fraction,
.js-swiper-testimonials .swiper-pagination-fraction {
  position: static;
  display: inline-flex;
  align-items: center;
  height: 64px;
  width: auto;
  transform: none;
  margin-top: 44px;
}
.js-swiper-members .swiper-pagination-fraction.swiper-pagination-lock,
.js-swiper-testimonials .swiper-pagination-fraction.swiper-pagination-lock {
  display: none;
}
.js-swiper-members .swiper-button-prev,
.js-swiper-members .swiper-button-next,
.js-swiper-testimonials .swiper-button-prev,
.js-swiper-testimonials .swiper-button-next {
  position: static;
  display: inline-flex;
  width: 64px;
  height: 64px;
  color: #1E2626;
  margin-top: 44px;
  margin-right: 1rem;
}
.js-swiper-members .swiper-button-prev svg,
.js-swiper-members .swiper-button-next svg,
.js-swiper-testimonials .swiper-button-prev svg,
.js-swiper-testimonials .swiper-button-next svg {
  width: 70%;
  height: 70%;
}
.js-swiper-members .swiper-button-prev:after,
.js-swiper-members .swiper-button-next:after,
.js-swiper-testimonials .swiper-button-prev:after,
.js-swiper-testimonials .swiper-button-next:after {
  display: none;
}
.js-swiper-members .swiper-button-prev:hover,
.js-swiper-members .swiper-button-next:hover,
.js-swiper-testimonials .swiper-button-prev:hover,
.js-swiper-testimonials .swiper-button-next:hover {
  color: #005666;
}
.js-swiper-members .swiper-button-prev:focus-visible,
.js-swiper-members .swiper-button-next:focus-visible,
.js-swiper-testimonials .swiper-button-prev:focus-visible,
.js-swiper-testimonials .swiper-button-next:focus-visible {
  box-shadow: 0 0 0 2px #F2F2F2, 0 0 0 4px #005666;
}
.js-swiper-members .swiper-button-prev.swiper-button-disabled,
.js-swiper-members .swiper-button-next.swiper-button-disabled,
.js-swiper-testimonials .swiper-button-prev.swiper-button-disabled,
.js-swiper-testimonials .swiper-button-next.swiper-button-disabled {
  opacity: 1;
  color: #D9D9D9;
}
.js-swiper-members .swiper-button-prev.swiper-button-lock,
.js-swiper-members .swiper-button-next.swiper-button-lock,
.js-swiper-testimonials .swiper-button-prev.swiper-button-lock,
.js-swiper-testimonials .swiper-button-next.swiper-button-lock {
  display: none;
}
@media (min-width: 768px) {
  .js-swiper-members .swiper-pagination-fraction,
  .js-swiper-members .swiper-button-prev,
  .js-swiper-members .swiper-button-next,
  .js-swiper-testimonials .swiper-pagination-fraction,
  .js-swiper-testimonials .swiper-button-prev,
  .js-swiper-testimonials .swiper-button-next {
    margin-top: 32px;
  }
}

.testimonials-section {
  padding: 40px 0;
  background-color: #FBFBFB;
}
.testimonials-section .section-title {
  margin-bottom: 24px;
}
.testimonials-section .section-text {
  margin-bottom: 24px;
}
@media (max-width: 767.98px) {
  .testimonials-section .btn-holder .btn {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .testimonials-section {
    padding: 64px 0;
  }
  .testimonials-section .section-title {
    margin-bottom: 64px;
  }
  .testimonials-section .section-text {
    font-size: 20px;
    margin-bottom: 64px;
  }
}

.js-swiper-testimonials {
  padding: 20px;
  margin: -20px -20px 16px;
}
.js-swiper-testimonials .swiper-slide {
  height: auto;
}
.js-swiper-testimonials .swiper-slide .card-testimonial {
  height: 100%;
  margin-bottom: 0;
}
.js-swiper-testimonials .swiper-pagination-fraction {
  font-size: 22px;
}
@media (min-width: 768px) {
  .js-swiper-testimonials {
    margin-bottom: 48px;
    margin-left: -50vw;
    margin-right: -50vw;
    padding-left: 50vw;
    padding-right: 50vw;
  }
}

.card-testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 90, 0.1);
}
.card-testimonial .card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 258px;
  padding: 32px;
}
.card-testimonial .card-img img, .card-testimonial .card-img svg {
  width: 100%;
}
.card-testimonial .card-body {
  position: relative;
  padding: 32px;
}
.card-testimonial .card-body::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #F2F2F2;
}
.card-testimonial .card-text {
  color: #005666;
  margin-bottom: 1rem;
}
.card-testimonial .card-footer {
  display: flex;
  align-items: center;
}
.card-testimonial .author-img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 24px;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .card-testimonial .img-wrapper {
    text-align: center;
  }
  .card-testimonial .img-wrapper img {
    max-width: 70%;
  }
}
@media (min-width: 992px) {
  .card-testimonial {
    flex-direction: row;
  }
  .card-testimonial .name,
  .card-testimonial .company-name {
    font-size: 18px;
  }
  .card-testimonial .company-name {
    font-weight: 500;
  }
  .card-testimonial .card-img {
    flex: 1 1 0;
  }
  .card-testimonial .card-body {
    flex: 1 1 0;
  }
  .card-testimonial .card-body::before {
    top: 40px;
    left: 0;
    bottom: 40px;
    width: 1px;
    height: auto;
    background-color: #F2F2F2;
  }
  .card-testimonial .card-text {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.6;
    max-width: 500px;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .card-testimonial {
    min-height: 436px;
  }
}
@supports (-webkit-appearance:none){

}

 @media (min-width: 768px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 767.98px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 991.98px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 992px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 1200px){

@supports (-webkit-appearance:none){

}

}
