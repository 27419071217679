@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/maps';

@import '../../src/scss/components/vars';
@import '../../src/scss/components/mixins';

.cards-section {
  padding: 40px 0;
  &.section-bg {
    background-color: $white;
  }
  .section-title,
  .section-text {
    margin-bottom: 24px;
  }
  .home & {
    .section-title,
    .section-text {
      margin-bottom: 48px;
    }
  }
  @include media-breakpoint-down(md) {
    .btn-holder {
      .btn {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding: $margin-lg 0;
    &.section-bg {
      + .cards-section.section-bg {
        margin-top: -64px;
      }
    }
    &:not(.section-bg) {
      + .cards-section:not(.section-bg) {
        margin-top: -64px;
      }
      + .join-section {
        margin-top: -64px;
      }
    }
    .section-title {
      margin-bottom: 48px;
    }
    .section-text {
      font-size: 22px;
      line-height: 1.6;
      margin-bottom: 48px;
    }

    &.two_col_desktop {
      .section-text {
        max-width: $width-7-col;
      }
      .card-default {
        .card-img {
          height: 342px;
        }
      }
    }
    .home & {
      //padding: 80px 0;
      .section-title,
      .section-text {
        margin-bottom: 64px;
      }
    }
  }
}


.card-default-group {
  display: flex;
  flex-wrap: wrap;
  gap: 48px $grid-gutter-width;
  margin: 24px 0;
  .home & {
    margin: 48px 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .card-default {
    .three_col_desktop & {
      //&:nth-of-type(3n + 1) {
      //  .card-img {
      //    background-color: $secondary;
      //  }
      //}
      //&:nth-of-type(3n + 2) {
      //  .card-img {
      //    background-color: $primary-dark;
      //  }
      //}
      //&:nth-of-type(3n + 3) {
      //  .card-img {
      //    background-color: $primary;
      //  }
      //}
    }
    .four_col_desktop & {
      //&:nth-of-type(4n + 1) {
      //  .card-img {
      //    background-color: $secondary;
      //  }
      //}
      //&:nth-of-type(4n + 2) {
      //  .card-img {
      //    background-color: $primary-dark;
      //  }
      //}
      //&:nth-of-type(4n + 3) {
      //  .card-img {
      //    background-color: $primary;
      //  }
      //}
      //&:nth-of-type(4n + 4) {
      //  .card-img {
      //    background-color: rgba($secondary,.6);
      //  }
      //}
    }
  }
  @include media-breakpoint-up(md) {
    margin-top: 48px;
    margin-bottom: 48px;
    .home & {
      margin-top: 64px;
      margin-bottom: 64px;
    }
    .card-default {
      width: calc(50% - 16px);
    }
  }
  @include media-breakpoint-up(xl) {
    .card-default {
      width: calc(25% - 24px);
    }
    &.two_col_desktop {
      .card-default {
        width: calc(50% - 16px);
      }
    }
    &.three_col_desktop {
      .card-default {
        width: calc(33.33334% - 22px);
      }
    }
  }
}
.card-default {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $white;
  border-radius: $radius-sm $radius-sm $radius $radius;
  box-shadow: $box-shadow-alt;
  overflow: hidden;
  transition: background-color $tr-anim;
  &[href] {
    text-decoration: none;
    &:hover {
      background-color: $gray-lightest;
    }
  }
  .two_col_desktop & {
    //box-shadow: $box-shadow;
    .card-body {
      //border-radius: 0 0 $radius-sm $radius-sm;
      //border: none;
    }
  }
  .card {
    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      //background-color: $primary;
      background-color: $white;
      width: 100%;
      height: 258px;
    }
    &-body {
      flex-grow: 1;
      padding: 32px;
      border-radius: 0 0 $radius $radius;
    }
    &-title {
      font-family: $ff;
      font-size: 28px;
      font-weight: 700;
      color: $primary-dark;
      margin-bottom: 1rem;
    }
    &-text {
      font-size: 16px;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.photo {
    .card-img {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &.vector {
    .card-img {
      img {
        width: 80%;
        height: 80%;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .card-text {
      font-size: 1rem;
      .home & {
        font-size: 20px;
      }
    }
  }
}