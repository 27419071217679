@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/maps';

@import '../../src/scss/components/vars';
@import '../../src/scss/components/mixins';

.carbon-calculator-section {
  background-color: $white;
  padding: 40px 0;
  .col-img {
    .img-holder {
      img {
        width: auto;
      }
    }
  }
  .section-title {
    span {
      font-weight: 400;
    }
  }
  .section-text {
    margin-bottom: 1.5rem;
    h3 {
      strong {
        position: relative;
        display: inline-block;
        font-size: 28px;
        letter-spacing: -.4px;
        &::before {
          position: absolute;
          left: -1.5rem;
          right: -1.5rem;
          top: -1rem;
          bottom: -1rem;
          content: '';
          background-color: rgba($primary, .1);
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .col-img {
      //display: none;
      margin-top: 1.5rem;
      .img-holder {
        text-align: center;
      }
    }
    .btn-holder {
      margin-bottom: 1rem;
      .btn {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-up(md) {
    //padding: 80px 0;
    padding: $margin-lg 0;
    .section-inner {
      @include row;
      align-items: center;
    }
    .col-text,
    .col-img {
      @include col;
    }
    .col-img {
      .img-holder {
        padding-left: 1.5rem;
      }
    }
    .section-title {
      margin-bottom: 3rem;
    }
    .section-text {
      font-size: 20px;
      line-height: 1.6;
      margin-bottom: 3rem;
    }
  }
  @include media-breakpoint-up(lg) {
    .col-img {
      max-width: 33.33334%;
      flex: 0 0 33.33334%;
    }
    .col-text {
      margin-left: 16.66667%;
      max-width: 50%;
      flex: 0 0 50%;
    }
  }
}