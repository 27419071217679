@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/maps';

@import '../../src/scss/components/vars';
@import '../../src/scss/components/mixins';

.latest-news-section {
  padding: 40px 0;
  &.section-bg {
    background-color: $white;
  }
  .section-title {
    margin-bottom: 40px;
  }
  @include media-breakpoint-down(md) {
    .btn-holder {
      .btn {
        width: 100%;
      }
      &.desktop-hidden {
        text-align: center;
        .btn {
          width: auto;
        }
      }
    }
    .heading {
      .btn-holder {
        display: none;
      }
    }
    .card-news-group {
      + .btn-holder {
        &.desktop-hidden {
          margin-top: -1rem;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding: 64px 0;
    .home & {
      //padding: 80px 0;
    }
    .heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 48px;
      .section-title {
        margin-bottom: 0;
      }
    }
    .section-title {
      margin-bottom: 64px;
    }
  }
}
