:root,
[data-bs-theme=light] {
  --bs-link-color-rgb: #1D1D1D;
  --bs-link-hover-color-rgb: #1D1D1D;
}

.card-news-group {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 32px;
  margin: 40px 0;
}
@media (min-width: 768px) {
  .card-news-group .card-news {
    width: calc(50% - 16px);
  }
}
@media (min-width: 1200px) {
  .card-news-group .card-news {
    width: calc(33.33334% - 22px);
  }
}

.card-news {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 90, 0.1);
  overflow: hidden;
}
.card-news[href] {
  color: #1D1D1D;
  text-decoration: none;
}
.card-news[href]:hover {
  color: #fff;
}
.card-news[href]:hover .card-body {
  background-color: #005666;
}
.card-news[href]:hover .card-title {
  text-decoration: underline;
}
.card-news .card-img {
  position: relative;
  width: 100%;
  height: 275px;
  background-color: #F2F2F2;
}
.card-news .card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-news .card-img .cat-holder {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 16px;
}
.card-news .card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 32px;
  transition: background-color 0.3s ease-in-out;
}
.card-news .card-inner {
  flex-grow: 1;
}
.card-news .card-title {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
  color: inherit;
  margin-bottom: 1rem;
}
.card-news .card-text {
  font-size: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  margin-bottom: 1rem;
}
.card-news .date {
  font-size: 14px;
  font-weight: 700;
}

.cat {
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  padding: 1rem 2rem;
  color: #1D1D1D;
  background-color: #FF0099;
}
@supports (-webkit-appearance:none){

}

 @media (min-width: 768px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 1200px){

@supports (-webkit-appearance:none){

}

}
